import styled from "styled-components";
import CustomButton from '../custom-button/custom-button.component';

export const StatusButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;
export const StyleStatus = styled(CustomButton)`
  width: 100vw;
  background-color: #3b7097;
  color: white;
  border: none;
  &:hover {
    background-color: #f46c3f;
    color: white;
    border: none;
  }
`;