import React from "react";
import PasswordChange from "../../../components/change-password/change-password.component";
import { PageContainer } from "./passwordChange.styles.jsx"; 
import MenuBar from "../../../components/menu-bar/menu-bar.component";
const PassPage = () => (
    <PageContainer>
    <MenuBar />
     <PasswordChange />   
    </PageContainer>

);

export default PassPage;