import styled from "styled-components";
import { Link } from "react-router-dom";

export const MenuBarContainer = styled.div`
  display: flex;
  justify-content: space-around; /* Center and evenly distribute the menu items */
  align-items: center;
  background-color: #3b7097;
  padding: 15px 0; /* Reduced padding for a sleeker look */
  position: fixed;
  bottom: 0;
  left: 0; /* Added to align with the left edge */
  width: 100%;
  z-index: 99;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2); /* Added a subtle shadow for separation */
`;

export const OptionLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 16px; /* Reduced font size for better readability */
  display: flex;
  transition: color 0.2s ease;

  &:not(:last-child) {
    margin-right: 30px; /* Spaced out the menu items */
  }

  &:hover {
    color: #f46c3f;
  }
`;

export const IconStyle = {
  marginRight: "8px",
  fontSize: "18px", /* Reduced font size for better alignment */
};
export const RedDot = styled.div`
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 4px;
`;