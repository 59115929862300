import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { logout } from '../../redux/auth/auth.actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from "styled-components";
import io from 'socket.io-client';
import axios from 'axios';

const NavBar = () => {
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const [notification, setNotification] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = io('', {
      transports: ['websocket'],
    });

    useEffect(() => {
      fetchChatHistories();
      socket.on('chat-message', (message) => {
        setNotification(true);
      });
  
      return () => {
        // ยกเลิกการรับข้อมูลเมื่อ unmount
        socket.off('chat-message');
      };
  }, [currentUser, notification, socket]);

  const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
      };


  const fetchChatHistories = async () => {
        try {
          const back2userResponse = await axios.get('/api/all-unread');
    
          if (back2userResponse.data.length > 0) {
            setNotification(true);
          }
        } catch (error) {
          console.error('เกิดข้อผิดพลาดในการดึงข้อมูลแชท:', error);
        }
      };

      const resetNotification = () => {
            setNotification(false);
      };

      const logoutButtonStyle = {
        marginLeft: 'auto', // ให้ปุ่ม "LOGOUT" อยู่ด้านขวา
      };
      const menuStyle = {
        fontFamily: 'Noto Sans Thai', // ให้ปุ่ม "LOGOUT" อยู่ด้านขวา
      };

     const RedDot = styled.div`
     display: flex;
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: 18px;
    margin-left: -25px;
`;
  return (
    <Menu theme="light" mode="horizontal" style={menuStyle}>
      <Menu.Item key="1">
        <Link to="/dashboard">รายชื่อผู้ใช้</Link>
      </Menu.Item>
      <Menu.Item key="7">
        <Link to="/dashboard/product-list">รายชื่อสินค้า</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/dashboard/distribute-log">มอบหมายคำสั่งซื้อ</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/dashboard/sell-log">บันทึกการร่วมซื้อ</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="/dashboard/delivery-log">ควบคุมการจัดส่ง</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/dashboard/withdraw">การถอนเงิน</Link>
      </Menu.Item>
      <Menu.Item key="6" onClick={resetNotification}>
        <Link to="/dashboard/support">Support Chat</Link>
      </Menu.Item>
      {notification && <Menu.Item key="6"><RedDot /></Menu.Item>}
      <Menu.Item to="/login" onClick={handleLogout} style={logoutButtonStyle}>
          LOGOUT
        </Menu.Item>
    </Menu>
  );
};

export default NavBar;
