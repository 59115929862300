import React, { useState, useEffect } from "react";
import axios from "axios";
import { StatusButtonContainer, StyleStatus } from "./status-btn.styles";
import { useSelector } from "react-redux";

const StatusButton = () => {
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const [workStatus, setWorkStatus] = useState(1);

  // โหลดค่า work_status จากฐานข้อมูลเมื่อคอมโพเนนต์ถูกเรนเดอร์
  useEffect(() => {
    // สร้างฟังก์ชัน async เพื่อดึงข้อมูลจาก API
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/statusbtn", {

          params: { username: currentUser },
        });
        const fetchedWorkStatus = response.data.workStatus;
        setWorkStatus(fetchedWorkStatus);
      } catch (error) {
        console.error("เกิดข้อผิดพลาดในการดึงข้อมูล work_status:", error);
      }
    };

    fetchData(); // เรียกใช้ฟังก์ชัน fetchData เมื่อคอมโพเนนต์ถูกเรนเดอร์
  }, [currentUser]);

  // ฟังก์ชันสำหรับการคลิกปุ่ม
  const handleButtonClick = async () => {
    try {
      // ส่งคำสั่งไปยัง API สำหรับการอัปเดต work_status
      await axios.put("/api/status-action", { username: currentUser });

      // อัปเดตค่า workStatus เมื่อคลิกปุ่ม
      setWorkStatus(2);
      window.location.reload();
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการอัปเดต work_status:", error);
    }
  };

  // สร้างข้อความขึ้นอยู่กับค่า workStatus
  let buttonText = "รับรายการสินค้า";
  let buttonColor = { backgroundColor: "#3b7097" };
  let isButtonDisabled = false;

  if (workStatus === "2") {
    buttonText = "กรุณารอการสั่งซื้อ";
    buttonColor = { backgroundColor: "#f46c3f" };
    isButtonDisabled = true;
  } else if (workStatus === "3") {
    buttonText = "ได้รับการมอบหมายแล้ว";
    buttonColor = { backgroundColor: "gray" };
    isButtonDisabled = true;
  }

  return (
    <StatusButtonContainer>
      <StyleStatus onClick={handleButtonClick} style={buttonColor} disabled={isButtonDisabled}>
        {buttonText}
      </StyleStatus>
    </StatusButtonContainer>
  );
};

export default StatusButton;
