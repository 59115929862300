import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ItemContainer, OrderImage, DetailContainer, AcceptBtn, NameItem, Cost, Price, Commision } from './order-item.styles';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  });
  return formatter.format(value);
};

const OrderItem = ({ data }) => {
  const navigate = useNavigate();
  const [userCredit, setUserCredit] = useState(null);

  useEffect(() => {
    fetchData(data);
  }, [data]);

  const fetchData = (data) => {
    axios
      .get(`/api/user-credit?username=${data.username}`)

      .then((response) => {
        setUserCredit(response.data[0]);
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const handleJoinPurchase = async () => {
    try {
      const response = await axios.put('/api/buy-order', {

        distribute_id: data.id,
        username: data.username,
        product_name: data.product_name,
        credit: userCredit.credit,
        product_cost: data.cost,
        commission: data.commission,
        admin_username: data.admin_username,
      });

      if (response.status === 200) {
        message.success('ร่วมซื้อสำเร็จ');
        navigate('/order/sell');
      } else {
        console.error('มีข้อผิดพลาดในการร่วมซื้อสำเร็จ');
      }
    } catch (error) {
      console.error('มีข้อผิดพลาดในการร่วมซื้อสำเร็จ', error);
    }
  };
  const redTextStyle = {
    color: 'red',
    marginLeft: '60px',
  };
  return (
    <ItemContainer>
      <OrderImage src={data.img1} />
      <DetailContainer>
        <NameItem>{data.product_name}</NameItem>
        <Cost>ราคาตัวแทน {formatCurrency(parseFloat(data.cost))}</Cost>
        <Price>ราคาขาย {formatCurrency(parseFloat(data.price))}</Price>
        <Commision>กำไร {formatCurrency(parseFloat(data.commission))}</Commision>
      </DetailContainer>
      {userCredit !== null && userCredit.credit >= data.cost ? (
        <AcceptBtn onClick={handleJoinPurchase}>ร่วมซื้อ</AcceptBtn>
      ) : (
        <p style={redTextStyle}>ยอมรวมไม่เพียงพอกรุณาติดต่อฝ่ายบริการ</p>
      )}
    </ItemContainer>
  );
};

export default OrderItem;
