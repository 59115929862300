import styled from "styled-components";
import { Link } from "react-router-dom";

export const Box2Container = styled.div`
  background-color: white;
  color: #3b7097;
  padding: 10px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 18px;
`;

export const ItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const Item = styled(Link)`
  text-decoration: none;
  text-align: center;
  color: #3b7097;
  flex-grow: 1;
  margin: 0 10px;

  img {
    max-width: 100%;
  }

  span {
    margin-top: 5px;
    display: block;
  }

  &:hover {
    color: #f46c3f;
  }
`;

export const IconStyle = {
    marginRight: "8px",
    fontSize: "24px",
  };