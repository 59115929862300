import React, { useState, useEffect } from "react";
import io from 'socket.io-client'
import { FaHome, FaComment, FaUser } from "react-icons/fa";
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  MenuBarContainer,
  OptionLink,
  IconStyle,
  RedDot
} from "./menu-bar.styles";

const MenuBar = () => {
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const [notification, setNotification] = useState(false);
  const socket = io('', {
    transports: ['websocket'],
  });

  useEffect(() => {
      fetchChatHistories();
      socket.on('chat-message', (message) => {
        setNotification(true);
      });
  
      return () => {
        // ยกเลิกการรับข้อมูลเมื่อ unmount
        socket.off('chat-message');
      };
  }, [currentUser, notification, socket]);

  const fetchChatHistories = async () => {
    try {
      const back2userResponse = await axios.get('/api/back2user-unread', {
        params: { recipient: currentUser },
      });

      if (back2userResponse.data.length > 0) {
        setNotification(true);
      }
    } catch (error) {
      console.error('เกิดข้อผิดพลาดในการดึงข้อมูลแชท:', error);
    }
  };

  const resetNotification = async () => {
    try {
      const back2userResponse = await axios.put('/api/read-all', {
        recipient: currentUser,
      });

      if (back2userResponse.status === 200) {
        setNotification(false);
      }
    } catch (error) {
      console.error('เกิดข้อผิดพลาดในการตั้งค่าการแจ้งเตือน:', error);
    }
  };

  return (
    <MenuBarContainer>
      <OptionLink to="/">
        <FaHome style={IconStyle} /> HOME
      </OptionLink>
      <OptionLink to="/chat" onClick={resetNotification}>
        <FaComment style={IconStyle} /> CHAT US {notification && <RedDot />}
      </OptionLink>
      <OptionLink to="/profile">
        <FaUser style={IconStyle} /> PROFILE
      </OptionLink>
    </MenuBarContainer>
  );
};

export default MenuBar;
