import React from 'react';
import { Carousel } from 'antd';

const Corousel = () => {
  // รายการ URL ของรูปภาพ
  const imageUrls = [
    'https://terminal-xpress.com/corousel/img1.jfif',
    'https://terminal-xpress.com/corousel/img2.jfif',
    'https://terminal-xpress.com/corousel/img3.jfif',
    'https://terminal-xpress.com/corousel/img4.jfif',
    'https://terminal-xpress.com/corousel/img5.jfif',
    'https://terminal-xpress.com/corousel/img6.jfif',
    'https://terminal-xpress.com/corousel/img8.jfif',
    'https://terminal-xpress.com/corousel/img9.jfif',
    'https://terminal-xpress.com/corousel/img10.jfif',
    'https://terminal-xpress.com/corousel/img12.jfif',
    'https://terminal-xpress.com/corousel/img13.jfif',
    'https://terminal-xpress.com/corousel/img14.jfif',
    'https://terminal-xpress.com/corousel/img15.jfif',
    'https://terminal-xpress.com/corousel/img16.jfif',
    'https://terminal-xpress.com/corousel/img17.jfif',

    // เพิ่ม URL ของรูปภาพเพิ่มเติมตามที่คุณต้องการ
  ];

  return (
    <Carousel autoplay >
      {imageUrls.map((imageUrl, index) => (
        <div key={index}>
          <img src={imageUrl} alt={`Image ${index + 1}`} style={{ width: '500px', height: '300px', objectFit: 'cover', margin: '40px auto 0 auto' }} />
        </div>
      ))}
    </Carousel>
  );
};

export default Corousel;
