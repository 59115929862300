import styled from "styled-components";
import CustomButton from "../custom-button/custom-button.component";

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  background-color: white;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  }
  @media (max-width: 768px) {
    margin-left: -40px;
  }
`;

export const OrderImage = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 150px;
  height: 150px;
  }
`;

export const DetailContainer = styled.div`
  display: block; /* แสดงเป็น block */
  margin-left: 50px; /* ชิดด้านขวา */
  padding-right: 20px; /* เพิ่มระยะขาวขวา */
  @media (max-width: 768px) {
    margin-left: 20px;
  }
`;
export const NameItem = styled.div`
  padding: 20px 10px; /* เพิ่มระยะขาวขวา */
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
export const Cost = styled.div`
  padding: 10px; /* เพิ่มระยะขาวขวา */
  font-size: 18px;
  color:#3b7097;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const Price = styled.div`
  padding: 10px; /* เพิ่มระยะขาวขวา */
  font-size: 18px;
  color:#f46c3f;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const Commision = styled.div`
  padding: 10px; /* เพิ่มระยะขาวขวา */
  font-size: 18px;
  color:#32CD32;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const AcceptBtn = styled(CustomButton)`
 background-color: #4a8db7;
  color: white;
  border: none;
  margin-left: auto;
  margin-right: 20px;
  font-size: 20px;
  &:hover {
    background-color: #f46c3f;
    color: white;
    border: none;
  }
`;
