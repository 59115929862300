import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MenuBar from '../../../components/menu-bar/menu-bar.component';
import { useSelector } from 'react-redux';
import { Table, Layout, Input, Button, message } from 'antd';

function SellLog() {
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const [searchValue, setSearchValue] = useState('');
  const [sellData, setSellData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/user-sell-log?currentUser=${currentUser}`);
      setSellData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = () => {
    // Implement your search logic here and set the result in the 'sellData' state
  };

  const resetSearch = () => {
    setSearchValue('');
    fetchData();
  };

  const productImageStyle = {
    maxWidth: '150px',
    maxHeight: '150px',
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
    },
    {
      title: 'ภาพสินค้า',
      dataIndex: 'img1',
      key: 'img1',
      render: (imgSrc) => (
        <img
          src={imgSrc}
          alt="ภาพสินค้า"
          style={productImageStyle}
        />
      ),
      responsive: ['md'],
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'คำอธิบาย',
      dataIndex: 'description',
      key: 'description',
      responsive: ['md'],
    },
    {
      title: 'ราคาตัวแทน',
      dataIndex: 'product_cost',
      key: 'product_cost',
      render: (product_cost) => (
        <span>
          {parseFloat(product_cost).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} บาท
        </span>
      ),
    },
    {
      title: 'ผลตอบแทน',
      dataIndex: 'commission',
      key: 'commission',
      render: (commission) => (
        <span>
          {parseFloat(commission).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} บาท
        </span>
      ),
    },
    {
      title: 'ดำเนินการ',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        if (record.delivery_status === '1') {
          return (
            <Button
              type="primary"
              onClick={() => handleDelivery(record)}
            >
              จัดส่ง
            </Button>
          );
        } else {
          return (
        <span> ส่งคำขออนุมัติแล้ว </span>
          );
        }
      },
    },
  ];

  
  const handleDelivery = async (record) => {
    
    try {
      const postData = {
        product_name: record.product_name,
        product_cost: record.product_cost,
        commission: record.commission,
        username: record.username,
        admin_username: record.admin_username,
      };
      const response = await axios.put(`/api/delivery-req/${record.id}`, postData);
      if (response.status === 200) {
        message.success('ส่งคำขออนุมัติสำเร็จ');
        window.location.reload();
        fetchData();
      } else {
        console.error('เกิดข้อผิดพลาดในการจัดส่ง');
        if (response.data.message) {
          message.error(response.data.message);
        } else {
          message.error('เกิดข้อผิดพลาดในการจัดส่ง');
        }
      }
    } catch (error) {
      console.error('เกิดข้อผิดพลาดในการจัดส่ง:', error);
    }
  };

  return (
    <div>
      <MenuBar />
      <Layout style={{ height: '100vh', padding: 0 }}>
        <Layout>
          <Layout.Header style={{ padding: '0 16px', background: '#001529', color: 'white' }}>
            <h1 style={{ fontSize: '24px', margin: '0', fontFamily: 'Noto Sans Thai' }}>บันทึกขายออก</h1>
          </Layout.Header>
          <Layout.Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
            <Input
              placeholder="ค้นหา..."
              enterButton="ค้นหา"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onPressEnter={handleSearch}
              style={{ marginBottom: '16px' }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Button type="primary" onClick={resetSearch}>รีเซ็ตค้นหา</Button>
            </div>
            <Table dataSource={sellData} columns={columns} />
          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default SellLog;
