// UserWithdraw.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MenuBar from '../../components/menu-bar/menu-bar.component';
import { Form, Input, Button, message, Table, InputNumber } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';

const PageContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 20px;
  width: 800px;
  margin: 10px auto;

  @media (max-width: 768px) {
    width: 70%;
    padding: 10px;
    margin: 10px;
  }
`;

const UserWithdraw = () => {
  const [userData, setUserData] = useState([]);
  const [form] = Form.useForm();
  const currentUser = useSelector((state) => state.auth.currentUser.username);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/user-withdraw', {
        params: {
          currentUser: currentUser,
        },
      });

      if (response.status === 200) {
        const userData = response.data;
        setUserData(userData);
      } else {
        console.error('Error fetching data from the database');
      }
    } catch (error) {
      console.error('Error fetching data from the database', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      // Send a POST request to submit withdrawal request
      const response = await axios.post('/api/user-withdraw', {
        ...values, // Form data
        username: currentUser, // Include the username of the current user
      });

      if (response.status === 200) {
        form.resetFields();
        // Refresh the data after a successful submission
        fetchData();
        message.success('ยื่นการถอนเงินสำเร็จ');
      } else if (response.status === 400) {
        message.error('ยอดรวมไม่เพียงพอ');
      } else if (response.status === 401) {
        message.error('รหัสถอนเงินผิด');
      }
    } catch (error) {
      console.error('เกิดข้อผิดพลาด', error);
      message.error('เกิดข้อผิดพลาด'); // แสดงข้อความเมื่อเกิดข้อผิดพลาด
    }
  };

  const columns = [
    {
      title: 'NO.',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ชื่อบัญชี',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'ธนาคาร',
      dataIndex: 'bank',
      key: 'bank',
    },
    {
      title: 'เลขบัญชี',
      dataIndex: 'acc_no',
      key: 'acc_no',
      responsive: ['md'],
    },
    {
      title: 'ยอดเงิน',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (
        <span>
          {parseFloat(amount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <PageContainer>
      <MenuBar />
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item label="ยอดเงิน" name="amount" rules={[{ required: true, message: 'กรุณากรอกยอดเงิน' }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="รหัสถอนเงิน"
          name="pin_code"
          rules={[
            { required: true, message: 'กรุณากรอกรหัสถอนเงิน' },
            {
              min: 6,
              message: 'รหัสถอนเงินควรมีอย่างน้อย 6 ตัว',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            ถอนเงิน
          </Button>
        </Form.Item>
      </Form>
      <br />
      <br />
      <Table columns={columns} dataSource={userData} />
    </PageContainer>
  );
};

export default UserWithdraw;
