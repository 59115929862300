import React, { useState, useEffect } from 'react';
import { Layout, Table, Input, Button } from 'antd';
import axios from 'axios';
import NavBar from '../../../components/navbar/navbar.component';

const { Search } = Input;
const { Header, Content } = Layout;

const WithDraw = () => {
  const [userData, setUserData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get('/api/withdraw-log')
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const allowWithdraw = (record) => {
    axios.put(`/api/withdraw-log/${record.id}`)
      .then((response) => {
        // อัปเดตข้อมูลใหม่หลังจากอนุมัติ
        fetchData();
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการอนุมัติ: ', error);
      });
  };

  const denyWithdraw = (record) => {
    axios.delete(`/api/withdraw-log/${record.id}`)
      .then((response) => {
        // อัปเดตข้อมูลใหม่หลังจากยกเลิก
        fetchData();
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการยกเลิก: ', error);
      });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    // ในกรณีนี้คุณสามารถกรองข้อมูลที่ต้องการจาก userData แล้วเซ็ตให้กับ state ใหม่
    const filteredData = userData.filter((user) => {
      return user.username.includes(value) || user.email.includes(value);
    });
    setUserData(filteredData);
  };

  const resetSearch = () => {
    setSearchValue('');
    fetchData(); // เรียกใช้ฟังก์ชัน fetchData เพื่อรีเซ็ตข้อมูล
  };

  const columns = [
    {
      title: 'เลขที่',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'ชื่อบัญชี',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'ธนาคาร',
      dataIndex: 'bank',
      key: 'bank',
    },
    {
      title: 'เลขบัญชี',
      dataIndex: 'acc_no',
      key: 'acc_no',
    },
    {
      title: 'ยอดเงิน',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (
        <span>
          {parseFloat(amount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      
    },
    {
      title: 'เวลายื่นเรื่อง',
      dataIndex: 'time_stamp',
      key: 'time_stamp',
      
    },
    {
      title: 'ดำเนินการ',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        if (record.status === 'รอการอนุมัติ') {
          return (
            <>
              <Button
                type="primary"
                onClick={() => allowWithdraw(record)}
              >
                อนุมัติ
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => denyWithdraw(record)}
              >
                ยกเลิก
              </Button>
            </>
          );
        } else {
          return (
            <span>อนุมัติแล้ว</span>
          );
        }
      },
    },
    
  ];

  return (
    <Layout style={{ height: '100vh', padding: 0 }}>
      <Layout>
        <NavBar />
        <Header style={{ padding: '0 16px', background: '#001529', color: 'white' }}>
          <h1 style={{ fontSize: '24px', margin: '0', fontFamily: 'Noto Sans Thai' }}>การถอนเงิน</h1>
        </Header>
        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          <Search
            placeholder="ค้นหา..."
            enterButton="ค้นหา"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
            style={{ marginBottom: '16px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Button type="primary" onClick={resetSearch}>รีเซ็ตค้นหา</Button>
          </div>

          <Table dataSource={userData} columns={columns} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default WithDraw;