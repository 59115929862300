import React from "react";
import { Box2Container, Title, ItemBox, Item, IconStyle } from './optional-box.styles';
import { FaCoins, FaCartArrowDown, FaMoneyCheckAlt } from "react-icons/fa";

const OptionalBox = () => (
  <Box2Container className="box2">
    <Title>การขาย</Title>
    <ItemBox>
      <Item to='/order/sell'>
        <FaCoins style={IconStyle} />
        <span>ขายออก</span>
      </Item>
      <Item to='/order/deliver'>
        <FaCartArrowDown style={IconStyle} />
        <span>จัดส่ง</span>
      </Item>
    </ItemBox>
  </Box2Container> 
);
export default OptionalBox;