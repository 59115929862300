import styled from 'styled-components';

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* เปลี่ยนเป็น 2 คอลัมน์ */
  grid-gap: 10px; /* เพิ่มระยะห่างระหว่างไอเทม */
  padding: 1rem 10px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const HeadTitle = styled.div`
font-size: 24px;
margin-left: 20px;
`;