import styled from "styled-components";

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  transition: all 0.2s ease;
  background-color: white;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  }
`;

export const OrderImage = styled.img`
  width: 230px;
  height: 230px;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 100px;
  height: 100px;
  }
`;

export const DetailContainer = styled.div`
  display: block; /* แสดงเป็น block */
  margin-left: 50px; /* ชิดด้านขวา */
  padding-right: 20px; /* เพิ่มระยะขาวขวา */
`;
export const NameItem = styled.div`
  padding: 20px 10px; /* เพิ่มระยะขาวขวา */
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
export const Desc = styled.div`
  padding: 10px; /* เพิ่มระยะขาวขวา */
  font-size: 18px;
  color: gray;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

