import React from "react";
import styled from "styled-components";

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0 ;
  flex-wrap: wrap;
`;

const LinkItem = styled.div`
  text-decoration: none;
  text-align: center;
  color: #333;
  margin: 10px;
  flex-grow: 1;
`;

const ImageBox = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 40px;
  height: 40px; /* ให้แสดง 2 คอลัมน์ ในหน้าจอเล็ก */
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
`;

const BrandLogo = () => (
  <LinkContainer className="lt">
    <LinkItem href="">
      <ImageBox>
        <Image src="https://loodibee.com/wp-content/uploads/Adidas-Logo.png" alt="adidas" />
      </ImageBox>
    </LinkItem>

    <LinkItem href="">
      <ImageBox>
        <Image src="https://www.pngmart.com/files/23/Tommy-Hilfiger-Logo-PNG-File.png" alt="tommy" />
      </ImageBox>
    </LinkItem>
    <LinkItem href="">
      <ImageBox>
        <Image src="https://pngimg.com/d/nike_PNG5.png" alt="nike" />
      </ImageBox>
    </LinkItem>

    <LinkItem href="">
      <ImageBox>
        <Image src="https://seeklogo.com/images/O/onitsuka-tiger-logo-2E5D5B465B-seeklogo.com.png" alt="onitsuka" />
      </ImageBox>
    </LinkItem>

    <LinkItem href="">
      <ImageBox>
        <Image src="https://1000logos.net/wp-content/uploads/2020/01/Swarovski-Logo.png" alt="schwa" />
      </ImageBox>
    </LinkItem>

    <LinkItem href="">
      <ImageBox>
        <Image src="https://1000logos.net/wp-content/uploads/2020/03/Kenzo-Logo-2020.png" alt="kenzo" />
      </ImageBox>
    </LinkItem>
    <LinkItem href="">
      <ImageBox>
        <Image src="https://seeklogo.com/images/C/chloe-logo-C62282D027-seeklogo.com.png" alt="chloe" />
      </ImageBox>
    </LinkItem>
    <LinkItem href="">
      <ImageBox>
        <Image src="https://www.edigitalagency.com.au/wp-content/uploads/victorias-secret-logo-png.png" alt="vtsc" />
      </ImageBox>
    </LinkItem>
    <LinkItem href="">
      <ImageBox>
        <Image src="https://grafica-facile.com/wp-content/uploads/2022/01/Ralph-Lauren-Logo.png" alt="polo" />
      </ImageBox>
    </LinkItem>
    <LinkItem href="">
      <ImageBox>
        <Image src="https://logos-world.net/wp-content/uploads/2021/08/Champion-Logo.png" alt="champion" />
      </ImageBox>
    </LinkItem>
    <LinkItem href="">
      <ImageBox>
        <Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Pandora_Logo_2019.svg/2560px-Pandora_Logo_2019.svg.png" alt="pandora" />
      </ImageBox>
    </LinkItem>
    <LinkItem href="">
      <ImageBox>
        <Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Aldo_Group_logo.svg/2560px-Aldo_Group_logo.svg.png" alt="aldo" />
      </ImageBox>
    </LinkItem>

</LinkContainer>
);

export default BrandLogo;
