import React from "react";
import Login from "../../components/form-login/form-login.component";

const styles = {
    loginPage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // ให้ขยายขนาดหน้าจอทั้งความสูง
    },
};

const LoginPage = () => (
    <div style={styles.loginPage}>
        <Login />
    </div>
);

export default LoginPage;
