import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import "./change-password.styles.css";
import { useSelector } from "react-redux";


const PasswordChange = () => {
    const currentUser = useSelector((state) => state.auth.currentUser.username);
  
    const [form] = Form.useForm();
  
    const handleSubmit = async (values) => {
      try {
        // สร้างข้อมูลที่จะส่งไปยัง API โดยรวม currentUser
        const requestData = {
          ...values,
          username: currentUser, // เพิ่ม currentUser ลงในข้อมูลที่จะส่ง
        };
  
        // ส่งคำขอ PUT ไปยังเซิร์ฟเวอร์ที่ localhost:3001/api/changePassword
        await axios.put("/api/change-password", requestData);
  
        // เมื่อสำเร็จในการเปลี่ยนรหัสผ่าน คุณสามารถทำอะไรก็ตามที่คุณต้องการ
        message.success("เปลี่ยนรหัสผ่านสำเร็จ");
  
        // ล้างค่าฟอร์ม
        form.resetFields();
      } catch (error) {
        message.error("เกิดข้อผิดพลาด");
      }
    };
  
    return (
      <div className="sign-in">
        <h2>เปลี่ยนรหัสผ่าน</h2>
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="รหัสผ่านเก่า"
            name="old_password"
            rules={[
              { required: true, message: "กรุณากรอกรหัสผ่านเก่า" },
            ]}
          >
            <Input.Password />
          </Form.Item>
  
          <Form.Item
            label="รหัสผ่านใหม่"
            name="new_password"
            rules={[
              { required: true, message: "กรุณากรอกรหัสผ่านใหม่" },
            ]}
          >
            <Input.Password />
          </Form.Item>
  
          <Form.Item
            label="ยืนยันรหัสผ่านใหม่"
            name="cf_new_password"
            dependencies={["new_password"]}
            rules={[
              { required: true, message: "กรุณายืนยันรหัสผ่านใหม่" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("รหัสผ่านไม่ตรงกัน");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
  
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };
  
  export default PasswordChange;
  