import styled from "styled-components";

export const UserProfileContainer = styled.div`
  color: #1c1c1e;
  padding: 16px;
  display: flex;
  justify-content: space-between; /* จัดวางข้อมูลฝั่งซ้ายและขวา */
  align-items: center;
  gap: 16px;
  background-color: #3b7097;
  color: white;
`;

export const AvatarLink = styled.a`
  text-decoration: none;
`;

export const AvatarImage = styled.img`
  height: 70px;
  width: 70px;
  object-fit: contain;
  border-radius: 50%;
  border: 4px solid rgba(200,200,200,0.4);
  @media screen and (max-width: 768px) {
    height: 50px;
  width: 50px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-grow: 1; /* ให้ InfoContainer ขยายตามพื้นที่ที่เหลือ */
  justify-content: flex-end; /* จัดวางข้อมูลฝั่งซ้ายและขวา */
  align-items: center;
`;

export const LeftInfoContainer = styled.div`
  flex-grow: 1; /* ให้ LeftInfoContainer ขยายตามพื้นที่ที่เหลือ */
`;

export const RightInfoContainer = styled.div`
  flex-grow: 0; /* ให้ RightInfoContainer ขยายตามพื้นที่ที่เหลือ */
`;

export const Nickname = styled.span`
  font-size: 18px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const UserId = styled.span`
  color: white;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const MemberLevel = styled.div`
  font-size: 16px;
  margin-left: auto; /* ชิดด้านขวา */
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const InviteCode = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const InviteSpan = styled.span`
  font-size: 14px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const CopyIcon = styled.img`
  width: 16px;
  height: 16px;
  @media screen and (max-width: 768px) {
    width: 12px;
  height: 12px;
  }
`;

export const CreditScore = styled.div`
  font-size: 16px;
  margin-left: auto; /* ชิดด้านขวา */
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
