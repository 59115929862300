import React from "react";
import Register from "../../components/form-register/form-register.component";

const styles = {
  registerPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "400px", // ให้ความสูงขั้นต่ำเป็น 100% ของหน้าจอ
    padding: "20px", // เพิ่ม padding สำหรับหน้าจอขนาดเล็ก
  },
};

const RegisterPage = () => (
  <div style={styles.registerPage}>
    <Register />
  </div>
);

export default RegisterPage;
