import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import HomePage from "./pages/homepage/homepage.component";
import ProfilePage from "./pages/profile/profile.component";
import CardPage from "./pages/user/card/card.component";
import PassPage from "./pages/user/passwordChange/passwordChange.component";
import SellLog from "./pages/order/sell/order-log.component";
import DeliLog from "./pages/order/deliver/dellog.component";
import UserList from "./pages/dashboard/user-list/user-list.component";
import SetUserPage from "./pages/dashboard/set-user/register.component";
import WithDraw from "./pages/dashboard/withdraw/withdraw.component";
import SellPage from "./pages/dashboard/sell-log/sell-log.component";
import DeliveryPage from "./pages/dashboard/delivery-log/delivery-log.component";
import DistPage from "./pages/dashboard/distribute-log/distribute-log.component";
import RegisterPage from "./pages/register/register.component";
import LoginPage from "./pages/login/login.component";
import AddProductPage from "./pages/dashboard/add-product/add-product.component";
import ProductListPage from "./pages/dashboard/product-list/product-list.component";
import UserWithdraw from "./pages/user-withdraw/user-withdraw.component";
import ChatApp from "./pages/chat/chat.component";
import ChatList from "./pages/dashboard/support/support.component";
import { selectCurrentUser } from "./redux/auth/auth.selectors";
import { login } from "./redux/auth/auth.actions";
import './App.css';

function App({ currentUser }) {
  if (!currentUser) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  if (currentUser.permission === '3') { // User (3)
    return (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/chat" element={<ChatApp />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/user/card" element={<CardPage />} />
        <Route path="/user/passwordChange" element={<PassPage />} />
        <Route path="/order/sell" element={<SellLog />} />
        <Route path="/order/deliver" element={<DeliLog />} />
        <Route path="/user-withdraw" element={<UserWithdraw />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  if (currentUser.permission === '1' || currentUser.permission === '2') { // Admin (1) or Support (2)
    return (
      <Routes>
        <Route path="/dashboard" element={<UserList />} />
        <Route path="/dashboard/withdraw" element={<WithDraw />} />
        <Route path="/dashboard/sell-log" element={<SellPage />} />
        <Route path="/dashboard/delivery-log" element={<DeliveryPage />} />
        <Route path="/dashboard/distribute-log" element={<DistPage />} />
        <Route path="/dashboard/set-user" element={<SetUserPage />} />
        <Route path="/dashboard/product-list" element={<ProductListPage />} />
        <Route path="/dashboard/add-product" element={<AddProductPage />} />
        <Route path="/dashboard/support" element={<ChatList />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
}


const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(login(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
