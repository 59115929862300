import React from "react";
import { ListContainer, HeadTitle, HiddenLink } from './directory.styles';
import DirItem from "../dir-item/dir-item.component";

class Directory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collections: [],
      displayedCollections: [], // เพิ่ม state สำหรับรายการที่จะแสดง
    };
  }

  componentDidMount() {
    // เรียก API เพื่อดึงข้อมูล
    fetch('/api/product-list')

      .then(response => response.json())
      .then(data => {
        this.setState({ collections: data }, () => {
          this.randomlySelectCollections(); // เรียกฟังก์ชันเพื่อเลือกรายการแบบสุ่ม
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  // ฟังก์ชันสำหรับเลือกรายการแบบสุ่ม
  randomlySelectCollections() {
    const { collections } = this.state;
    const randomCollections = collections.sort(() => 0.5 - Math.random()).slice(0, 10);
    this.setState({ displayedCollections: randomCollections });
  }

  render() {
    const { displayedCollections } = this.state; // เรียกใช้ displayedCollections แทน collections

    return (
      <div>
        <HeadTitle>สินค้าทั้งหมด</HeadTitle>
        <ListContainer>
          {displayedCollections.map(({ id, product_name, description, img1 }) => (
            <DirItem key={id} name={product_name} description={description} imgUrl={img1} />
          ))}
        </ListContainer>
      </div>
    );
  }
}

export default Directory;
