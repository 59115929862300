import React from "react";
import UserProfile from "../../components/user-profile/user-profile.component";
import BalanceBox from "../../components/balance-box/balance-box.component";
import OptionalBox from "../../components/optional-box/optional-box.component";
import SettingBox from "../../components/profile-setting/profile-setting.component";
import MenuBar from "../../components/menu-bar/menu-bar.component";
const ProfilePage = () => (
    <div className="profile">
    <MenuBar />
    <UserProfile />
    <BalanceBox />
    <OptionalBox />
    <SettingBox />

    </div>
)

export default ProfilePage;