import React from "react";
import ProductForm from "../../../components/product-form/product-form.component";
import NavBar from "../../../components/navbar/navbar.component";
const styles = {
    registerPage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // ให้ขยายขนาดหน้าจอทั้งความสูง
    },
};
const AddProductPage = () => (
    <div>
    <NavBar />
    <div style={styles.registerPage}>
      <ProductForm />  
    </div>
    </div>
)

export default AddProductPage;