import React from "react";

import { ItemContainer, OrderImage, DetailContainer, NameItem, Desc } from './dir-item.styles';

const DirItem = ({ name, description, imgUrl }) => (
<ItemContainer>
  <OrderImage src={imgUrl} />
  <DetailContainer>
<NameItem>{name}</NameItem>
<Desc>{description}</Desc>

  </DetailContainer>
</ItemContainer>
);

export default DirItem;