import React, { useState, useEffect } from 'react';
import { Layout, Table, Input, Button, Modal, Form, InputNumber } from 'antd';
import axios from 'axios';
import NavBar from '../../../components/navbar/navbar.component';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Search } = Input;
const { Header, Content } = Layout;

const UserList = () => {
  const currentUser = useSelector(state => state.auth.currentUser.username);
  const permissionUser = useSelector(state => state.auth.currentUser.permission);
  const [userData, setUserData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [editedCredit, setEditedCredit] = useState('');
  const [editedScore, setEditedScore] = useState('');

  const canAddUser = permissionUser === "1";

  useEffect(() => {
    fetchData(currentUser); // ส่ง currentUser เข้าไปใน fetchData
  }, [currentUser]);
  const fetchData = (currentUser) => {
    axios
      .get('/api/users')

      .then((response) => {
        const noLoginData = response.data.filter((user) => user.username !== currentUser);
        setUserData(noLoginData);
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredData = userData.filter((user) => {
      return user.username.includes(value) ;
    });
    setUserData(filteredData);
  };
  const resetSearch = () => {
    setSearchValue('');
    if (searchValue === '') {
      // ถ้า searchValue ว่าง ให้ดึงข้อมูล "No Login"
      fetchData(currentUser);
    } else {
      // ถ้ามีการค้นหา ให้รีเซ็ตค่า searchValue และค้นหาอีกครั้ง
      setSearchValue('');
      fetchData(currentUser);
    }
  };

  const handleEdit = (user) => {
    setEditedUser(user);
    setEditedCredit(user.credit); // ตั้งค่าค่าเริ่มต้นใน Modal จากข้อมูลผู้ใช้เดิม
    setEditedScore(user.score); // ตั้งค่าค่าเริ่มต้นใน Modal จากข้อมูลผู้ใช้เดิม
    setIsEditModalVisible(true);
  };
  const confirmEdit = () => {
    if (editedUser) {
      axios
        .put(`/api/users/${editedUser.id}`, {

          credit: editedCredit,
          score: editedScore,
        })
        .then((response) => {
          if (response.status === 200) {
            // อัปเดตข้อมูลยอดรวมและคะแนนใน state หลังจากแก้ไขสำเร็จ
            const updatedUserData = userData.map((user) => {
              if (user.id === editedUser.id) {
                return { ...user, credit: editedCredit, score: editedScore };
              }
              return user;
            });
            setUserData(updatedUserData);
          } else {
            console.error('เกิดข้อผิดพลาดในการแก้ไขผู้ใช้');
          }
        })
        .catch((error) => {
          console.error('เกิดข้อผิดพลาดในการแก้ไขผู้ใช้: ', error);
        })
        .finally(() => {
          setIsEditModalVisible(false);
          setEditedUser(null);
        });
    }
  };

  const handleDelete = (user) => {
    setUserToDelete(user);
    setIsDeleteModalVisible(true);
  };

  const confirmDelete = () => {
    if (userToDelete) {
      axios
      .delete(`/api/users/${userToDelete.id}`)

        .then((response) => {
          if (response.status === 200) {
            // ลบผู้ใช้จาก state หลังจากลบสำเร็จ
            const updatedUserData = userData.filter((user) => user.id !== userToDelete.id);
            setUserData(updatedUserData);
          } else {
            console.error('เกิดข้อผิดพลาดในการลบผู้ใช้');
          }
        })
        .catch((error) => {
          console.error('เกิดข้อผิดพลาดในการลบผู้ใช้: ', error);
        })
        .finally(() => {
          setIsDeleteModalVisible(false);
          setUserToDelete(null);
        });
    }
  };

  

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'โค๊ดเชิญ',
      dataIndex: 'refcode',
      key: 'refcode',
    },
    {
      title: 'คะแนน',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'credit',
      key: 'credit',
      render: (credit) => (
        <span>
          {parseFloat(credit).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'ผู้รับผิดชอบ',
      dataIndex: 'hg',
      key: 'hg',
    },
    {
      title: 'Permission',
      dataIndex: 'permission',
      key: 'permission',
    },
    
    {
      title: 'ดำเนินการ',
      key: 'action',
      render: (text, record) => (
        <>
        { canAddUser && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Button type="primary" onClick ={() => handleEdit(record)}>
              ยอดรวมและคะแนน
            </Button>
            <Button type="primary" danger onClick={() => handleDelete(record)}>
              ลบ
            </Button>
            </div>
        )}
        </>
      ),
    }
    
  ];

  return (
    <Layout style={{ height: '100vh', padding: 0 }}>
      <Layout>
        <NavBar />
        <Header style={{ padding: '0 16px', background: '#001529', color: 'white' }}>
          <h1 style={{ fontSize: '24px', margin: '0', fontFamily: 'Noto Sans Thai' }}>รายชื่อผู้ใช้</h1>
        </Header>
        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          <Search
            placeholder="ค้นหา..."
            enterButton="ค้นหา"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
            style={{ marginBottom: '16px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          { canAddUser && (
            <Link to="/dashboard/set-user">
              <Button type="primary">เพิ่มผู้ใช้</Button>
            </Link>
            )}
            <Button type="primary" onClick={resetSearch}>
              รีเซ็ตค้นหา
            </Button>
          </div>

          <Table dataSource={userData} columns={columns} />
        </Content>
      </Layout>

      <Modal
        title="ยืนยันการลบผู้ใช้"
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsDeleteModalVisible(false)}>
            ยกเลิก
          </Button>,
          <Button key="delete" type="danger" onClick={confirmDelete}>
            ลบ
          </Button>,
        ]}
      >
        {userToDelete && <p>คุณต้องการลบผู้ใช้ {userToDelete.username} ใช่หรือไม่?</p>}
      </Modal>
      <Modal
        title="แก้ไขยอดรวมและคะแนน"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={confirmEdit}
      >
        <Form>
          <Form.Item label="ยอดรวม">
            <InputNumber
              value={editedCredit}
              onChange={(value) => setEditedCredit(value)}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label="คะแนน">
            <InputNumber
              value={editedScore}
              onChange={(value) => setEditedScore(value)}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default UserList;
