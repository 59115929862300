import { Box2Container, Title, ItemBox, Item, IconStyle } from './profile-setting.styles';
import React from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaLock, FaMoneyCheckAlt, FaPowerOff } from "react-icons/fa";
import { logout } from '../../redux/auth/auth.actions';

function SettingBox() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <Box2Container className="box2">
      <Title>ตั้งค่า</Title>
      <ItemBox>
        <Item to="/user/passwordChange">
          <FaLock style={IconStyle} />
          <span>เปลี่ยนรหัสผ่าน</span>
        </Item>
        <Item to="/user/card">
          <FaMoneyCheckAlt style={IconStyle} />
          <span>บัญชีธนาคาร</span>
        </Item>
        <Item to="/login" onClick={handleLogout}>
          <FaPowerOff style={IconStyle} />
          <span>LOGOUT</span>
        </Item>
      </ItemBox>
    </Box2Container>
  );
}

export default SettingBox;
