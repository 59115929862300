import React, { useState, useEffect } from "react";
import { ListContainer, HeadTitle, NoItem, NoItemContainer } from './order-list.styles';
import OrderItem from "../order-item/order-item.component";
import axios from "axios";
import { message } from 'antd';
import { useSelector } from "react-redux";

const OrderList = () => {
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const [distributeLogs, setDistributeLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [workStatus, setWorkStatus] = useState(2);

    const fetchStatus = async () => {
      try {
        const response = await axios.get("/api/statusbtn", {

          params: { username: currentUser },
        });
        const fetchedWorkStatus = response.data.workStatus;
        setWorkStatus(fetchedWorkStatus);
      } catch (error) {
        console.error("เกิดข้อผิดพลาดในการดึงข้อมูล work_status:", error);
      }
    };
    
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/fetch-order?currentUser=${currentUser}`);
        const data = response.data;
        setDistributeLogs(response.data);
      setLoading(false);
      if(data.length >= 1){
        setWorkStatus(3);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการเรียก API:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (workStatus === 2) {
      fetchStatus();
      fetchData();

      // Polling interval (e.g., every 5 seconds)
      const pollInterval = 5000; // 5 seconds in milliseconds

      const intervalId = setInterval(() => {
        fetchData(); // Fetch data periodically
      }, pollInterval);

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [currentUser]);

  return (
    <ListContainer>
      <HeadTitle>สินค้าที่ได้รับ</HeadTitle>
      {loading ? (
        <NoItemContainer>
        <NoItem href="https://pny.jpthinkofus.com">กำลังโหลดข้อมูล...</NoItem>
        </NoItemContainer>
      ) : (
        <ul>
          {distributeLogs.length === 0 ? (
          <NoItemContainer>
            <NoItem href="https://pny.jpthinkofus.com">ไม่มีรายการที่ได้รับ</NoItem>
          </NoItemContainer>
          ) : (
            distributeLogs.map((log, index) => (
              <OrderItem
                key={log.id}
                data={log}
              />
            ))
          )}
        </ul>
      )}
    </ListContainer>
  );
};

export default OrderList;
