import React from "react";
import UserProfile from "../../components/user-profile/user-profile.component";
import StatusButton from "../../components/status-button/status-btn.component";
import Corousel from "../../components/slide-show/corousel.component";
import PartnerLogo from "../../components/partner-logo/partner-logo.component";
import OrderList from "../../components/order-list/order-list.component";
import Directory from "../../components/directory/directory.component";
import MenuBar from "../../components/menu-bar/menu-bar.component";
import BrandLogo from "../../components/brand-logo/brand-logo.component";

const HomePage = () => (
  <div className="homepage">
    <MenuBar />
    <UserProfile />
    <Corousel />
    <PartnerLogo />
    <StatusButton />
    <OrderList />
    <BrandLogo />
    <Directory />
  </div>
);

export default HomePage;
