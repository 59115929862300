import React from "react";
import SetUser from "../../../components/form-register/add-acc.component";
import NavBar from "../../../components/navbar/navbar.component";
const styles = {
    registerPage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // ให้ขยายขนาดหน้าจอทั้งความสูง
    },
};
const SetUserPage = () => (
    <div>
    <NavBar />
    <div style={styles.registerPage}>
      <SetUser />  
    </div>
    </div>
)

export default SetUserPage;