import React, { useState, useEffect } from 'react';
import { Layout, Table, Input, Button, message, Modal } from 'antd';
import axios from 'axios';
import NavBar from '../../../components/navbar/navbar.component';
import { Link } from 'react-router-dom';

const { Search } = Input;
const { Header, Content } = Layout;

const ProductListPage = () => {
  const [productData, setProductData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false); // State สำหรับ Modal ยืนยันการลบ
  const [targetDeleteId, setTargetDeleteId] = useState(null); // State สำหรับเก็บ ID ที่ต้องการลบ

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get('/api/product-list')

      .then((response) => {
        setProductData(response.data);
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const handleDelete = (id) => {
    setTargetDeleteId(id); // เก็บ ID ที่ต้องการลบใน state
    setDeleteConfirmVisible(true); // เปิด Modal ยืนยันการลบ
  };

  const handleDeleteConfirm = () => {
    // ดำเนินการลบรายการที่เลือก
    axios.delete(`/api/product-list/${targetDeleteId}`)

      .then((response) => {
        if (response.status === 200) {
          // ลบรายการจาก state หลังจากลบจากเซิร์ฟเวอร์สำเร็จ
          const updatedProductData = productData.filter((product) => product.id !== targetDeleteId);
          setProductData(updatedProductData);
        } else {
          console.error('เกิดข้อผิดพลาดในการลบรายการ');
          if (response.data.message) {
            message.error(response.data.message);
          } else {
            message.error('เกิดข้อผิดพลาดในการลบรายการ');
          }
        }
        // ปิด Modal ยืนยันการลบ
        setDeleteConfirmVisible(false);
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการลบรายการ: ', error);
        message.error('เกิดข้อผิดพลาดในการลบรายการ');
        // ปิด Modal ยืนยันการลบ
        setDeleteConfirmVisible(false);
      });
  };

  const handleDeleteCancel = () => {
    // ยกเลิกการลบ ปิด Modal ยืนยันการลบ
    setDeleteConfirmVisible(false);
  };

  const productImageStyle = {
    maxWidth: '150px',
    maxHeight: '150px',
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ภาพสินค้า',
      dataIndex: 'img1',
      key: 'img1',
      render: (imgSrc) => (
        <img
          src={imgSrc}
          alt="ภาพสินค้า"
          style={productImageStyle}
        />
      ),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'คำอธิบาย',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'ราคาตัวแทน',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => (
        <span>
          {parseFloat(cost).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'ราคาขาย',
      dataIndex: 'price',
      key: 'price',
      render: (cost) => (
        <span>
          {parseFloat(cost).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'ผลตอบแทน',
      dataIndex: 'commission',
      key: 'commission',
      render: (cost) => (
        <span>
          {parseFloat(cost).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'ดำเนินการ',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" danger onClick={() => handleDelete(record.id)}>Delete</Button>
      ),
    },
  ];
  const handleSearch = (value) => {
    setSearchValue(value);
    // กรองข้อมูลสินค้าตามคำค้นหา
    const filteredData = productData.filter((product) => {
      return product.product_name.includes(value) || product.description.includes(value);
    });
    setProductData(filteredData);
  };
  const resetSearch = () => {
    setSearchValue('');
    fetchData(); // เรียกใช้ฟังก์ชัน fetchData เพื่อรีเซ็ตข้อมูล
  };

  return (
    <Layout style={{ height: '100vh', padding: 0 }}>
      <Layout>
        <NavBar />
        <Header style={{ padding: '0 16px', background: '#001529', color: 'white' }}>
          <h1 style={{ fontSize: '24px', margin: '0', fontFamily: 'Noto Sans Thai' }}>รายชื่อสินค้า</h1>
        </Header>
        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          <Search
            placeholder="ค้นหา..."
            enterButton="ค้นหา"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
            style={{ marginBottom: '16px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Link to='/dashboard/add-product'>
              <Button type="primary">เพิ่มสินค้า</Button>
            </Link>
            <Button type="primary" onClick={resetSearch}>รีเซ็ตค้นหา</Button>
          </div>
          <Table dataSource={productData} columns={columns} />

          {/* Modal ยืนยันการลบ */}
          <Modal
            title="ยืนยันการลบ"
            visible={deleteConfirmVisible}
            onOk={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          >
            <p>คุณแน่ใจหรือไม่ว่าต้องการลบรายการนี้?</p>
          </Modal>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ProductListPage;
