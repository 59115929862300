import React, { useState, useEffect, useRef } from 'react';
import { Layout, Table, Input, Button, Modal } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import NavBar from '../../../components/navbar/navbar.component';
import { useSelector } from 'react-redux';
import axios from 'axios';
import io from 'socket.io-client';
import { combineReducers } from 'redux';

const { Header, Content } = Layout;

const ChatContainer = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
  overflow-y: auto;
  height: 40vh;
`;

const Bubble = styled.div`
  padding: 10px;
  border-radius: 10px;
  width: 35%;
  margin: 5px 0;
  word-wrap: break-word;
`;
const LeftBubble = styled(Bubble)`
  background-color: #f2f2f2;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const RightBubble = styled(Bubble)`
  background-color: #ddd;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 60%;
`;
const LeftTime = styled.div`
  color: grey;
  text-align: right;
  margin-top: 5px;
  font-size: 6px;
`;
const RightTime = styled.div`
  color: grey;
  text-align: right;
  margin-top: 5px;
  font-size: 6px;
`;

const ImgContainer = styled.img`
  width: 150px;
  height: auto;
  margin: 5px;
`;
const FakeUpload = styled.label`
  border: none;
  padding: 0 16px;
  cursor: pointer;
  background-color: transparent;
  color: #000;
  border-radius: 4px;
  font-size: 20px;
`;
const InputFile = styled.input`
  display: none;
`;
const RedDot = styled.div`
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: auto 5px;
`;
const ChatList = () => {
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const permissionUser = useSelector((state) => state.auth.currentUser.permission);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [chatHistoryUser2Back, setChatHistoryUser2Back] = useState([]);
  const [chatHistoryBack2User, setChatHistoryBack2User] = useState([]);
  const [image, setImage] = useState(null);
  const [reqData, setReqData] = useState([]);
  const [notification, setNotification] = useState(false);
  const chatContainerRef = useRef(null);
  const canAddUser = permissionUser === "1";
  const socket = io('', {
    transports: ['websocket'],
  });
  const handleImageClick = (imageUrl) => {
    Modal.info({
      title: 'ขยายภาพ',
      content: <img src={imageUrl} alt="Enlarged" style={{ width: '100%' }} />,
      okText: 'Close',
    });
  };
  const fetchChatHistoryUser2Back = async () => {
    try {
      const response = await axios.get('/api/user2back-history', {
        params: { sender: selectedUsername },
      });
      const chatHistory = response.data;
      setChatHistoryUser2Back(chatHistory);
      setMessages(chatHistory);
    } catch (error) {
      console.error('Error fetching chat history from User2Back:', error);
    }
  };

  const fetchChatHistoryBack2User = async () => {
    try {
      const response = await axios.get('/api/back2user-history', {
        params: { reciepient: selectedUsername },
      });
      const chatHistory = response.data;
      setChatHistoryBack2User(chatHistory);
      setMessages(chatHistory);
    } catch (error) {
      console.error('Error fetching chat history from Back2User:', error);
    }
  };
  const fetchReqData = async () => {
    try {
      const response = await axios.get('/api/back2user-req');
      const reqData = response.data;
      setReqData(reqData);
    } catch (error) {
      console.error('Error fetching chat data:', error);
    }
  };
  useEffect(() => {
    fetchReqData();
  }, []);
  

  useEffect(() => {
    if (selectedUsername) {
      fetchChatHistoryUser2Back();
      fetchChatHistoryBack2User();
    }

    socket.on('chat-message', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off('chat-message');
    };
  }, [currentUser, selectedUsername, messages, socket]);

  const sendMessage = () => {
    if (messageInput) {
      axios
        .post('/api/back2user', {
          sender: currentUser,
          recipient: selectedUsername,
          message: messageInput,
        })
        .then((response) => {
          socket.emit('chat-message', {
            sender: currentUser,
            message: messageInput,
            time_stamp: new Date().toLocaleString(),
            status: true,
          });

          setMessageInput('');
        })
        .catch((error) => {
          console.error('Error saving chat data: ' + error);
        });
    }
  };
  const resetNotification = (record) => {
    axios
    .put(`/api/backreadAll/${record.sender}`)
      .then((response) => {
        setNotification(false);
        fetchReqData();
      })
      .catch((error) => {
        console.error('Error deleting chat: ', error);
      });
  };
  const handleDelete = (record) => {
    axios
      .delete(`/api/delete-chat/${record.sender}`)
      .then((response) => {
        fetchReqData();
      })
      .catch((error) => {
        console.error('Error deleting chat: ', error);
      });
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const formData = new FormData();
      formData.append('sender', currentUser);
      formData.append('reciepient', selectedUsername);
      formData.append('image', file);

      try {
        const response = await axios.post('/api/back2user-img', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setImage(null);
      } catch (error) {
        console.error('Error saving chat data: ' + error);
      }
    }
  };
  const columns = [
    {
      title: 'ชื่อผู้ใช้',
      dataIndex: 'sender',
      key: 'sender',
      render: (text, record) => (
        <>
          <span
            onClick={() => {
              setSelectedUsername(text);
              setModalVisible(true);
              resetNotification(record);
            }}
            style={{ cursor: 'pointer', color: 'blue', display: 'flex' }}
          >
          {record.unreadCount > 0 && <RedDot />}
            {text}
          </span>
        </>
      ),
    },
    {
      title: 'ติดต่อล่าสุด',
      dataIndex: 'max_timestamp',
      key: 'max_timestamp',
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <>
          {canAddUser && (
            <Button type="primary" danger onClick={() => handleDelete(record)}>
              Delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <Layout>
      <NavBar />
      <Header style={{ padding: '0 16px', background: '#001529', color: 'white' }}>
        <h1 style={{ fontSize: '24px', margin: '0', fontFamily: 'Noto Sans Thai' }}>Support Chat</h1>
      </Header>
      <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
        <Table columns={columns} dataSource={reqData} />
        <Modal
          title={`แชทกับ ${selectedUsername}`}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          <br />
          <ChatContainer ref={chatContainerRef}>
  {chatHistoryBack2User
    .concat(chatHistoryUser2Back)
    .sort((a, b) => a.time_stamp.localeCompare(b.time_stamp))
    .map((message, index) => {
      const isBack2User = chatHistoryBack2User.includes(message);

      return isBack2User ? (
        <RightBubble key={index}>
          {message.type === 'message' ? (
            <span>{message.message}</span>
          ) : message.type === 'image' ? (
            <ImgContainer
              src={message.message}
              onClick={() => handleImageClick(message.message)} // Handle image click
              style={{ cursor: 'pointer' }}
            />
          ) : (
            // เพิ่มเงื่อนไขที่คุณต้องการแสดงในกรณีอื่น ๆ ที่ message.type ต้องการ
            null
          )}
          <RightTime>{message.time_stamp}</RightTime>
        </RightBubble>
      ) : (
        <LeftBubble key={index}>
          {message.type === 'message' ? (
            <span>{message.message}</span>
          ) : message.type === 'image' ? (
            <ImgContainer
              src={message.message}
              onClick={() => handleImageClick(message.message)} // Handle image click
              style={{ cursor: 'pointer' }}
            />
          ) : (
            // เพิ่มเงื่อนไขที่คุณต้องการแสดงในกรณีอื่น ๆ ที่ message.type ต้องการ
            null
          )}
          <LeftTime>{message.time_stamp}</LeftTime>
        </LeftBubble>
      );
    })}
</ChatContainer>


          <br />
          <br />
          <FakeUpload>
            <InputFile
              type="file"
              name="image"
              onChange={handleImageChange}
            />
            <CameraOutlined />
          </FakeUpload>
          <Input
            rows={4}
            placeholder="Type your message here"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            onPressEnter={sendMessage}
            style={{ width: '66%' }}
          />
          <Button type="primary" onClick={sendMessage} style={{ width: '22%' }}>
            Send
          </Button>
        </Modal>
      </Content>
    </Layout>
  );
};

export default ChatList;
