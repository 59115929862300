import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Form, Input, Button, message, Table } from "antd";

const BindCard = () => {
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const [form] = Form.useForm();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetchDataFromDatabase();
  }, [currentUser]);

  const fetchDataFromDatabase = async () => {
    try {
      const response = await axios.get(
        "/api/update-bank-acc",
        {
          params: {
            currentUser,
          },
        }
      );

      if (response.status === 200) {
        const userData = response.data;
        setUserData(userData);
      } else {
        console.error("Failed to fetch data from the database");
      }
    } catch (error) {
      console.error("Failed to fetch data from the database", error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const response = await axios.put(
        "/api/update-bank-acc",
        {
          ...values,
          currentUser,
        }
      );

      if (response.status === 200) {
        form.resetFields(); // Reset the form
        message.success("บันทึกข้อมูลสำเร็จ");
        window.location.reload();
      } else {
        message.error("เกิดข้อผิดพลาด");
      }
    } catch (error) {
      console.error("Failed to save data", error);
    }
  };

  const columns = [
    {
      title: "ชื่อบัญชี",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "ธนาคาร",
      dataIndex: "bank",
      key: "bank",
    },
    {
      title: "เลขบัญชี",
      dataIndex: "acc_no",
      key: "acc_no",
    },
  ];

  return (
    <div className="sign-in">
      <h2>จัดการบัญชีธนาคาร</h2>
      <Table dataSource={userData} columns={columns} pagination={false} />
      <br />
      <br />
      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="ชื่อจริง"
          name="fullname"
          rules={[{ required: true, message: "กรุณากรอกชื่อจริง" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="ธนาคาร"
          name="bank"
          rules={[{ required: true, message: "กรุณากรอกชื่อธนาคาร" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="เลขที่บัญชี"
          name="acc_no"
          rules={[
            { required: true, message: "กรุณากรอกเลขที่บัญชี" },
            {
              validator: (_, value) => {
                if (!isNaN(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("กรุณากรอกเป็นตัวเลขเท่านั้น");
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="รหัสถอนเงิน"
          name="pin_code"
          rules={[
            { required: true, message: "กรุณากรอกรหัสถอนเงิน" },
            {
              min: 6,
              message: "รหัสถอนเงินควรมีอย่างน้อย 6 ตัว",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BindCard;
