import React from "react";
import BindCard from "../../../components/bind-card/bind-card.component";
import { PageContainer } from "./card.styles"; 
import MenuBar from "../../../components/menu-bar/menu-bar.component";
const PasswordPage = () => (
    <PageContainer>
    <MenuBar />
     <BindCard />   
    </PageContainer>

);

export default PasswordPage;