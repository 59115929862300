import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';
import { connect } from 'react-redux'; // import connect
import {
  UserProfileContainer,
  AvatarLink,
  AvatarImage,
  InfoContainer,
  LeftInfoContainer,
  RightInfoContainer,
  Nickname,
  UserId,
  MemberLevel,
  InviteCode,
  InviteSpan,
  CreditScore,
} from "./user-profile.styles";
import { FaCopy } from "react-icons/fa";

function UserProfile({ currentUser }) { // รับ currentUser จาก props
  const [userData, setUserData] = useState({});

  useEffect(() => {
    fetchData(currentUser);
  }, [currentUser]);

  const fetchData = (username) => {
    axios
      .get(`/api/user-credit?username=${currentUser.username}`)

      .then((response) => {
        setUserData(response.data[0]); // อ่านข้อมูลจาก response.data[0] เนื่องจาก API อาจส่งกลับข้อมูลเป็นอาร์เรย์
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };
  const formattedCredit = userData.credit ? userData.credit.toLocaleString() : '0';
  return (
    <UserProfileContainer>
      <AvatarLink href="">
        <AvatarImage src={userData.profile_img} alt="" />
      </AvatarLink>
      <InfoContainer>
        <LeftInfoContainer>
          <Nickname>{currentUser.username}</Nickname>
          <br />
          <UserId>User ID:{currentUser.userid}</UserId>
          <InviteCode>
            <InviteSpan>เลขที่ร้าน:</InviteSpan>
            <span>{currentUser.refcode}</span>
            <FaCopy />
          </InviteCode>
        </LeftInfoContainer>
        <RightInfoContainer>
          <CreditScore>ยอดรวม: {formattedCredit} บาท</CreditScore>
          <MemberLevel>คะแนน: {userData.score}</MemberLevel>
        </RightInfoContainer>
      </InfoContainer>
    </UserProfileContainer>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser, // ดึง currentUser จาก Redux state
});

export default connect(mapStateToProps)(UserProfile); // เชื่อมต่อ component ด้วย connect
