import React, { useState } from "react";
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Register() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // สร้าง URL สำหรับแสดงตัวอย่างรูปภาพ
    const imageURL = URL.createObjectURL(file);

    setImage(file);
    setImagePreview(imageURL);
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append('username', values.username);
      formData.append('password', values.password);
      formData.append('confirmPassword', values.confirmPassword);
      formData.append('phone', values.phone);
      formData.append('hgcode', values.hgcode);
      formData.append('img_profile', image); // Assuming it's a single file selection
      
      const response = await axios.post('/api/register', formData, {

        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log(response.data.message);
        message.success(response.data.message);
        navigate('/login');
      } else if (response.status === 400) {
        console.error('มีข้อผิดพลาดในการลงทะเบียน');
        message.error(response.data.message || 'มีข้อผิดพลาดในการลงทะเบียน');
      } else {
        console.error('มีข้อผิดพลาดในการลงทะเบียน');
        message.error('มีข้อผิดพลาดในการลงทะเบียน');
      }
    } catch (error) {
      console.error('มีข้อผิดพลาดในการลงทะเบียน:', error);
      if (error.response && error.response.data) {
        message.error(error.response.data.message || 'มีข้อผิดพลาดในการลงทะเบียน');
      } else {
        message.error('มีข้อผิดพลาดในการลงทะเบียน');
      }
    }
    
  };

  return (
    <div>
      <h2>Register</h2>
      <Form onFinish={onFinish}>
        <Form.Item
          label="ชื่อบัญชี(Username)"
          name="username"
          rules={[
            {
              required: true,
              message: 'กรุณากรอกชื่อบัญชี',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสผ่าน"
          name="password"
          rules={[
            {
              required: true,
              message: 'กรุณากรอกรหัสผ่าน',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="ยืนยันรหัสผ่าน"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'กรุณายืนยันรหัสผ่าน',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('รหัสผ่านไม่ตรงกัน');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="เบอร์โทรศัพท์(ใช้เข้าสู่ระบบ)"
          name="phone"
          rules={[
            {
              required: true,
              message: 'กรุณากรอกเบอร์โทรศัพท์',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสร้านค้า"
          name="hgcode"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รูปโปรไฟล์"
          name="img_profile"
          onChange={handleImageChange}
        >
          <Input type="file" />
          <br />
          {/* แสดงรูปภาพตัวอย่าง */}
          {imagePreview && (
            <img
              src={imagePreview}
              alt="รูปภาพ"
              style={{ maxWidth: "150px", maxHeight: "150px", margin: "10px" }}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Register;
