import styled from "styled-components";
import { Link } from "react-router-dom";
export const Box1Container = styled.div`
  background-color: white;
  padding: 16px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
margin: 50px 0 ;
font-size: 20px;
`;

export const Balance = styled.div`
  margin: 20px 0 ;
  color: #f46c3f;
`;

export const TabList = styled.div`
  display: flex;
  gap: 12px;
  margin: 20px 0;
`;

export const Tab = styled(Link)`
  text-decoration: none;
  color: white;
  background-color: #3b7097;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f46c3f;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const BottomItem = styled.div`
  text-align: center;
`;

export const PriceName = styled.div`
  color: #39aea9;
`;
